@import 'bootstrap-override/bootstrap-italia-site';

// @import 'Views/chiediAlBibliotecarioView';

@import 'Blocks/scopri-contenuti';
@import 'Blocks/suggerimentiBiblioteca';
@import 'Blocks/search-sections';
@import 'Blocks/searchInCatalogs';
@import 'Blocks/cardWithImageTemplate';
@import 'Blocks/card-slide-text';
@import 'Blocks/hero';
@import 'Blocks/icon-blocks';

.public-ui {
  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-tertiary {
    background-color: $buttons-color-override-imola;
  }

  .it-brand-wrapper a img.icon {
    height: auto;
  }

  .it-header-slim-wrapper {
    background-color: $header-nci;
    font-weight: 600;
  }

  .it-header-navbar-wrapper {
    .menu-wrapper {
      border-top: 1px solid transparentize($color: #fff, $amount: 0.5);
    }
  }

  .it-header-wrapper {
    .it-header-center-wrapper {
      background-color: $primary;

      .it-header-center-content-wrapper {
        .it-brand-wrapper a {
          .icon {
            height: auto;
          }

          h3 {
            color: $header-title-payoff;
            font-weight: 700;
          }

          .external-link {
            display: none;
          }
        }
      }
    }

    .navbar .navbar-collapsable .navbar-nav li.megamenu a.nav-link {
      font-weight: 700;
    }
  }

  .it-footer-main {
    a {
      .external-link {
        display: none;
      }
    }
  }

  .overflow-auto {
    overflow: auto;
  }

  .briciole {
    .external-link {
      display: none;
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .it-header-navbar-wrapper {
      background-color: $primary;
    }
    .it-header-wrapper {
      .it-header-center-wrapper {
        height: 160px;
      }
    }
  }

  // TODO: NEEDS TO BE FIXED IN DVT
  .container
    .block
    .searchSections:not(.button)
    .searchSections-background
    picture {
    height: auto;
    min-height: 100%;
  }

  // CUSTOMIZATION: no more blue layer #37795
  .squares-image-template .box::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
  }
}

.icon-argument-container .icon,
.icon-argument-container svg {
  color: $tertiary;
  fill: $tertiary;
}

@media (max-width: 991px) {
  .event-search {
    .d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
      justify-content: space-between !important;

      .me-lg-3.my-2.my-lg-1.filter-wrapper.text-filter {
        min-width: 45% !important;

        input {
          text-overflow: ellipsis;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .event-search {
    .d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
      flex-direction: column !important;
      justify-content: space-between !important;

      .me-lg-3.my-2.my-lg-1.filter-wrapper.text-filter,
      .me-lg-3.my-2.my-lg-1.filter-wrapper.select-filter,
      .me-lg-3.my-2.my-lg-1.filter-wrapper.date-filter {
        min-width: 100% !important;

        input {
          text-overflow: ellipsis;
        }

        .DateRangePicker {
          width: 100% !important;
        }
      }
    }
  }
}

body:not(.subsite) {
  .public-ui.draftjs-buttons a {
    background-color: $buttons-color-override-imola !important;
  }
}

.custom-imola-category-top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .head-tags {
    margin-bottom: 0 !important;
    .data {
      text-transform: none !important;
    }
  }
}

.contenttype-document {
  .title-description-wrapper {
    display: flex;
    flex-direction: column;
    .it-carousel-all.it-card-bg.page-header-gallery {
      overflow: visible;
      flex: 1;
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        display: none;
      }
      .slick-list {
        margin: 0 -0.2rem;
      }
      .slick-slider {
        width: 100%;
      }
      .slick-slide {
        margin: 0 0.2rem;
      }
      .slick-track {
        display: flex;
        flex-wrap: nowrap;
      }
      .volto-image.responsive img {
        object-fit: cover;
        object-position: center;
        aspect-ratio: 16/9 !important;
      }
    }
    &.col-lg-6 .it-carousel-all.it-card-bg.page-header-gallery {
      min-width: 120%;
    }
    &.col-lg-12 .it-carousel-all.it-card-bg.page-header-gallery {
      min-width: 60%;
      max-width: 60%;
      position: relative;
      top: unset;
    }
  }
}

// fix dark callout text
.public-ui .callout-bg,
.cms-ui .callout-bg {
  color: $primary-text;
}

.bottom-metadata,
#metadata {
  .subjects {
    p,
    .chip {
      font-family: 'Titillium Web';
    }

    .chip:hover {
      background-color: transparent;
      border-color: $primary;
      .chip-label {
        color: $primary;
      }
    }
  }
}
